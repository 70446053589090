import http from "@/plugins/http/http";
import OSS from "ali-oss";
import commonUtil from "@/util/commonUtil";

export default {
  getOssClient(isPrivate) {
    return new Promise((resolve, reject) => {
      http.get("sdk/oss/getToken").then(res => {
        let data = res.data;
        const client = new OSS({
          // yourRegion填写Bucket所在地域。以华东1（杭州）为例，Region填写为oss-cn-hangzhou。
          region: "oss-cn-shanghai",
          // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
          accessKeyId: data.accessKeyId,
          accessKeySecret: data.accessKeySecret,
          // 从STS服务获取的安全令牌（SecurityToken）。
          stsToken: data.securityToken,
          refreshSTSToken: async() => {
            // 向您搭建的STS服务获取临时访问凭证。
            const info = await http.get("sdk/oss/getToken");
            return {
              accessKeyId: info.data.accessKeyId,
              accessKeySecret: info.data.accessKeySecret,
              stsToken: info.data.stsToken,
            };
          },
          // 刷新临时访问凭证的时间间隔，单位为毫秒。
          refreshSTSTokenInterval: 900000,
          // 填写Bucket名称。
          bucket: isPrivate ? window.config.aliOssPrivateBucket : window.config.aliOssPublicBucket,
        });
        resolve(client);
      }).catch(err => {
        reject(err);
      });
    });
  },
  getFileKey(filename) {
    let lastName = filename.endsWith("tar.gz") ? ".tar.gz" : ("." + filename.split(".").pop());
    let name = commonUtil.randomString(12);
    return name + lastName;
  },
  upload(file, isPrivate, isImage, progress) {
    let filename = file.name;
    let fileKey = this.getFileKey(filename);
    const options = {
      // 获取分片上传进度、断点和返回值。
      progress: progress,
      // 设置并发上传的分片数量。
      parallel: 4,
      // 设置分片大小。默认值为1 MB，最小值为100 KB。
      partSize: 1024 * 1024,
      // headers,
      headers: {
        "Content-Disposition": `attachment; filename=${encodeURIComponent(filename)}`,
      },
    };
    return new Promise((resolve, reject) => {
      let folder = isImage ? "image/" : "file/";
      this.getOssClient(isPrivate).then(client => {
        // file表示浏览器中需要上传的文件，支持HTML5 file和Blob类型。
        client.multipartUpload(folder + fileKey, file, options).then(r => {
          console.log("上传完成", r);
          let url = r.res.requestUrls[0].split("?")[0];
          resolve({
            url,
            filename,
          });
        }).catch(function(err) {
          reject(err);
        });
      }).catch(err => {
        reject(err);
      });
    });
  },
};
